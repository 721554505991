:root {
  --form-control-color: rebeccapurple;
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 200px 1rem;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1.1em;
}

.form-control:focus-within {
  // color: var(--orange);
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--orange);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  // outline: max(2px, 0.15em) solid currentColor;
  // outline-offset: max(2px, 0.15em);
}


@media (max-width: 500px) {
 .form-control {
  justify-content: flex-start;
  text-align: left;
 }
}